<template>
  <div class="q-pa-md">
    <q-breadcrumbs>
      <q-breadcrumbs-el
        v-for="(crumb, key) in crumbs"
        :key="key"
        :label="crumb"
        @click="$router.go(1 - (crumbs.length - key))"
      />
    </q-breadcrumbs>

    <q-card>
      <q-list>
        <q-item v-for="directory in directories" :key="directory" v-ripple clickable>
          <q-item-section avatar>
            <q-avatar icon="folder" />
          </q-item-section>
          <q-item-section @click="$router.push(`/finance/export/${directory}`)">
            {{ directory }}
          </q-item-section>
        </q-item>

        <q-item v-for="file in files" :key="file.filename" v-ripple clickable>
          <q-item-section avatar>
            <q-avatar icon="mdi-file" />
          </q-item-section>
          <q-item-section @click="exportDownload(file.filename)">
            {{ file.display_name || file.filename.split('/').reverse()[0] }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>

<script>
import { exportListing, exportDownload } from 'api/finance'
export default {
  name: 'FinanceExport',
  data () {
    return {
      files: null,
      directories: null
    }
  },
  computed: {
    crumbs () {
      return this.$route.params.pathMatch.split('/')
    }
  },
  watch: {
    '$route.params.pathMatch': {
      immediate: true,
      handler (value) {
        exportListing(value)
          .then(response => {
            this.files = response.data.files
            this.directories = response.data.directories
          })
      }
    }
  },
  methods: {
    exportListing,
    exportDownload
  }
}
</script>
