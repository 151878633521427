import http from './http'
import processCsv from 'utils/process-csv'

export function exportListing (path) {
  return http.get(`/finance/export/listing`, { params: { path } })
}

export function exportDownload (path) {
  return http.get(`/finance/export/download`, { params: { path } }).then(csv => processCsv(csv, path))
}
