<template>
  <div class="q-pa-md">
    <q-breadcrumbs>
      <q-breadcrumbs-el
        v-for="(crumb, key) in crumbs"
        :key="key"
        :label="crumb"
        @click="$router.go(1 - (crumbs.length - key))"
      />
    </q-breadcrumbs>

    <q-card>
      <q-list>
        <q-item v-for="directory in directories" :key="directory" v-ripple clickable>
          <q-item-section avatar>
            <q-avatar icon="folder" />
          </q-item-section>
          <q-item-section @click="$router.push(`/finance/onwardtravel/export/${directory}`)">
            {{ directory }}
          </q-item-section>
        </q-item>

        <q-item v-for="file in files" :key="file.filename" v-ripple clickable>
          <q-item-section avatar>
            <q-avatar icon="mdi-file" />
          </q-item-section>
          <q-item-section @click="onwardTravelReportDownload(organisation.slug, file.filename)">
            {{ file.display_name || file.filename.split('/').reverse()[0] }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getOnwardTravelReports, onwardTravelReportDownload } from 'api/organisations'
import loading from 'utils/loading'
import i18n from 'i18n'

export default {
  name: 'OnwardTravelReportExport',
  data () {
    return {
      files: null,
      directories: null
    }
  },
  computed: {
    ...mapGetters(['organisation', 'partner']),
    crumbs () {
      return this.$route.params.pathMatch.split('/')
    }
  },
  watch: {
    '$route.params.pathMatch': {
      immediate: true,
      handler (value) {
        loading.start({
          message: i18n.t('loading.loading'),
          partnerSlug: this.partner.slug,
          spinnerColor: this.partner.hex
        })
        getOnwardTravelReports(this.organisation.slug, value)
          .then(response => {
            this.files = response.data.files
            this.directories = response.data.directories
            loading.stop()
          })
      }
    }
  },
  methods: {
    onwardTravelReportDownload
  }
}
</script>
